.container{
    width: 100%;
    min-height: 100dvh;
    background: #fafafa;
    padding: 20vh 20px 100px 20px;
}

.sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_container{
    width: 300px;
    min-height: 150px;
    background-color: #fff;
    box-shadow: 0 0 0 4px #F0F4F9,0 10px 20px rgba(0,0,0,0.1);
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 10px;
}

.center_icon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_container{
    width: 70px;
    height: 70px;
    background-color: #f15c69;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 16px #CC2936;
}

.icon{
    color: #fff;
    font-size: 30px;
}

.message_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.message{
    color: #333;
    font-size: 24px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 600;
}

.back_btn{
    padding: 6px 10px 6px 10px;
    background: #333;
    color: white;
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
    border: none;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}