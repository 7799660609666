.spinner {
   width: 19px;
   height: 19px;
   border: 4.2px #fff double;
   border-left-style: solid;
   border-radius: 50%;
   animation: spinner-aib1d7 0.75s infinite linear;
}

@keyframes spinner-aib1d7 {
   to {
      transform: rotate(360deg);
   }
}