.container{
    width: 100%;
    min-height: 100dvh;
    background: #1F2937;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0 40px 0;
}

.image_container{
    width: 300px;
    height: 550px;
    position: relative;
}

.image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chat_container{
    position: absolute;
    top: 75px;
    left: 38px;
    width: 220px;
    height: 235px;
    overflow: hidden;
    padding: 4px 2px;
}

.chat_sub_container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 1px 4px;
}

.number{
    padding: 10px;
    /* color: #1F2937; */
    font-size: 13px;
    font-weight: 600;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 0 0 1px #33333325;
    border-radius: 4px;
    background: #3F80F6;
background: linear-gradient(to right, #3F80F6 0%, #A257F7 50%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.lang{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 0 0 1px #33333325;
    border-radius: 4px;
    background: #3F80F6;
background: linear-gradient(to right, #3F80F6 0%, #A257F7 50%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.chat_input_output_container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    min-height: 150px;
    max-height: 150px;
    padding: 10px 4px 20px 4px;
    overflow-y: auto;
}

.chat_container_chat_box:nth-child(odd){
    width: 75%;
    align-self: flex-end;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.chat_container_chat_box:nth-child(odd) .chat_container_chat{
    width: 100%;
}

.chat_container_chat_box:nth-child(even){
    width: 75%;
    align-self: flex-start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.chat_container_chat_box:nth-child(even) .chat_container_chat{
    width: 100%;
}

.chat_container_chat_text{
    background: #F0F4F9;
    color: #333;
    font-family: 'Ubuntu',sans-serif;
    font-size: 12px;
    padding: 8px 8px;
    border-radius: 4px;
}

.chat_container_chat_box:nth-child(odd) .chat_container_chat_text{
    background: #C084FC;
    color: #fff;
}

.chat_container_chat_box:nth-child(even) .chat_container_chat_text{
    background: transparent;
    color: #333;
    border: 1px solid #C084FC;
}


.message_box_container{
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 0px 30px 10px 20px;
    margin: 0;
    z-index: 99;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    min-height: 100px;
    max-height: 100px;
    /* min-height: 140px;
    max-height: 140px; */
}

.message_wrapper{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 4px 0 4px;
}

.message_box{
    width: 100%;
    border-radius: 20px;
    resize: none; 
    background: #F0F4F9;
    min-height: 40px;
    max-height: 40px;
    border: none;
    outline: none;
    padding: 12px 60px 12px 20px;
    font-size: 12px;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 0 0 1px #333333b1;
}

.message_box::-webkit-scrollbar {
    width: 0px; 
}
.message_box::-webkit-scrollbar-thumb {
    background-color: #ffffff00;
    border-radius: 0px;
}

.arrow_wrapper{
    position: absolute;
    border: none;
    outline: none;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 120;
    width: 28px;
    height:28px;
    /* background: #000; */
    background: #00000022;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:not-allowed;
    transition: all 0.3s;
}

.arrow_wrapper.active{
    background: #000;
    cursor: pointer;
}

.arrow_up_icon{
    color: #fff;
    font-size: 12px;
}

@media screen and (max-width:750px){
    .message_box_container{
        padding: 0px 10px 10px 10px;
    }
}

@media screen and (max-width:1000px){
    .chat_container_chat_box:nth-child(odd){
        width: 90%;
    }
    .chat_container_chat_box:nth-child(even){
        width: 90%;
    }
}